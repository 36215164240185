$primary-color: #007bff;
$barlow-font-family: 'Barlow', sans-serif;
$black-bg: #27272A;
$white-text: #ffffff;
$primary-btn: #556F84;
$grey-text: rgba(0, 0, 0, 0.5);
$grey-light: #F4F4F6;
$grey-sky: #E7E5E4;
$ping-light: #971B2F;
$thik-green: #006C48;
$text-t-grey: #57534E;
$orange: #F8B588;
$cln-blue: #69A7DB;

@font-face {
  font-family: 'Calibre Regular';
  font-style: normal;
  src: local('Calibre Regular'), url('../public/fonts/calibre-cufonfonts-webfont/CalibreRegular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Calibre Regular Italic';
  font-style: normal;
  src: local('Calibre Regular Italic'), url('../public/fonts/calibre-cufonfonts-webfont/CalibreRegularItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Calibre Thin';
  font-style: normal;
  src: local('Calibre Thin'), url('../public/fonts/calibre-cufonfonts-webfont/CalibreThin.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Calibre Thin Italic';
  font-style: normal;
  src: local('Calibre Thin Italic'), url('../public/fonts/calibre-cufonfonts-webfont/CalibreThinItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Calibre Light';
  font-style: normal;
  src: local('Calibre Light'), url('../public/fonts/calibre-cufonfonts-webfont/CalibreLight.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Calibre Light Italic';
  font-style: normal;
  src: local('Calibre Light Italic'), url('../public/fonts/calibre-cufonfonts-webfont/CalibreLightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Calibre Medium';
  font-style: normal;
  src: local('Calibre Medium'), url('../public/fonts/calibre-cufonfonts-webfont/CalibreMedium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Calibre Medium Italic';
  font-style: normal;
  src: local('Calibre Medium Italic'), url('../public/fonts/calibre-cufonfonts-webfont/CalibreMediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Calibre Semibold';
  font-style: normal;
  src: local('Calibre Semibold'), url('../public/fonts/calibre-cufonfonts-webfont/CalibreSemibold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Calibre Semibold Italic';
  font-style: normal;
  src: local('Calibre Semibold Italic'), url('../public/fonts/calibre-cufonfonts-webfont/CalibreSemiboldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Calibre Bold';
  font-style: normal;
  src: local('Calibre Bold'), url('../public/fonts/calibre-cufonfonts-webfont/CalibreBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Calibre Bold Italic';
  font-style: normal;
  src: local('Calibre Bold Italic'), url('../public/fonts/calibre-cufonfonts-webfont/CalibreBoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Calibre Black';
  font-style: normal;
  src: local('Calibre Black'), url('../public/fonts/calibre-cufonfonts-webfont/CalibreBlack.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Calibre Black Italic';
  font-style: normal;
  src: local('Calibre Black Italic'), url('../public/fonts/calibre-cufonfonts-webfont/CalibreBlackItalic.woff') format('woff');
  font-display: swap;
}



.container-fl {
  max-width: 1536px;
  width: 100%;
  padding-right: 76px;
  padding-left: 76px;
  padding-top: 32px;
  padding-bottom: 32px;
  margin-right: auto;
  margin-left: auto;

  &.prints {
    @media screen and (max-width: 767px) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  &.navs {
    padding-bottom: 25px;
  }

  &.slicks {
    padding-right: 0px;

    @media screen and (max-width: 768px) {
      padding-right: 2px;
      padding-left: 2px;
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }

  @media screen and (max-width: 1200px) {
    padding-right: 42px;
    padding-left: 42px;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @media screen and (max-width: 768px) {
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @media screen and (max-width: 600px) {
    padding-right: 29px;
    padding-left: 29px;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @media screen and (max-width: 400px) {
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.primary-logo {
  width: 99px;
  height: 32px;
}

.gps-logo {
  height: 32px;
}

.custom-fonts {
  h1 {
    font-family: "Calibre Semibold";
    font-size: 96px;
    font-style: normal;
    letter-spacing: -1.33px;
    text-align: left;
    line-height: 102px;

    @media screen and (max-width: 1025px) {
      font-size: 72px;
      letter-spacing: -1px;
      line-height: 78px;
    }

    @media screen and (max-width: 640px) {
      font-size: 48px;
      letter-spacing: -0.67px;
      line-height: 48px;
    }
  }

  h3 {
    font-family: "Calibre Semibold";
    font-size: 72px;
    font-style: normal;
    letter-spacing: 0.56px;
    text-align: left;
    line-height: 78px;

    @media screen and (max-width: 1025px) {
      font-size: 64px;
      letter-spacing: 0.5px;
      line-height: 70px;
    }

    @media screen and (max-width: 640px) {
      font-size: 48px;
      letter-spacing: 0.38px;
      line-height: 54px;
    }

    @media screen and (max-width: 540px) {
      font-size: 40px;
      letter-spacing: .31px;
      line-height: 46px;
    }

    &.xl {
      font-family: "Calibre Light";
      font-size: 64px;
      font-style: normal;
      letter-spacing: 0.5px;
      text-align: left;
      line-height: 72px;

      @media screen and (max-width: 1200px) {
        font-size: 48px;
        letter-spacing: 0.38px;
        line-height: 64px;
      }


      @media screen and (max-width: 1025px) {
        font-size: 40px;
        letter-spacing: 0.31px;
        line-height: 48px;
      }


      @media screen and (max-width: 540px) {
        font-size: 32px;
        letter-spacing: .25px;
        line-height: 40px;
      }

    }

    &.xlx {
      font-family: "Calibre Medium";
      font-size: 64px;
      font-style: normal;
      letter-spacing: 0px;
      text-align: left;
      line-height: 72px;

      @media screen and (max-width: 1200px) {
        font-size: 64px;
        letter-spacing: 0px;
        line-height: 64px;
      }


      @media screen and (max-width: 1025px) {
        font-size: 56px;
        letter-spacing: 0px;
        line-height: 56px;
      }


      @media screen and (max-width: 640px) {
        font-size: 48px;
        letter-spacing: 0px;
        line-height: 48px;
      }

      @media screen and (max-width: 540px) {
        font-size: 40px;
        letter-spacing: 0px;
        line-height: 40px;
      }


    }
  }

  h4 {
    font-family: "Calibre Semibold";
    font-size: 40px;
    font-style: normal;
    letter-spacing: -1.25px;
    text-align: left;
    line-height: 36px;

    &.foot {
      font-size: 48px;
      letter-spacing: -1.5px;
    }

    @media screen and (max-width: 1025px) {
      font-size: 32px;
      letter-spacing: -1px;
      line-height: 36px;
    }
  }

  p {
    &.para-ms {
      font-family: "Calibre Medium";
      font-size: 24px;
      letter-spacing: 0.5px;
    }

    &.para-mix {
      font-family: "Calibre Semibold";
      font-size: 18px;
      letter-spacing: 0.14px;
    }

    &.para-mid {
      font-family: "Calibre Regular";
      font-size: 16px;
      letter-spacing: 0.13px;
    }

    &.para-msx {
      font-family: "Calibre Regular";
      font-size: 14px;
      letter-spacing: 0.11px;
    }

    &.para-label {
      font-family: "Calibre Medium";
      font-size: 16px;
      letter-spacing: -0.3px;
    }

    &.para-head {
      font-family: "Calibre Semibold";
      font-size: 24px;
      letter-spacing: 0.19px;

      @media screen and (max-width: 1025px) {
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 22px;
      }
    }

    &.label {
      font-family: "Calibre Medium";
      font-size: 12px;
      color: $white-text;
      letter-spacing: 0.38px;
    }

    &.label-foot {
      font-family: "Calibre Regular";
      font-size: 12px;
      color: $white-text;
      letter-spacing: 0px;
    }

    &.para-small {
      font-family: "Calibre Regular";
      font-size: 28px;
      letter-spacing: 0.5px;
      line-height: 38px;

      @media screen and (max-width: 1025px) {
        font-size: 24px;
        letter-spacing: 0.75px;
        line-height: 34px;
      }

      @media screen and (max-width: 639px) {
        font-size: 18px;
        letter-spacing: 0.38px;
        line-height: 24px;
      }
    }
  }

  ul {
    padding-left: 0px !important;
    font-size: 16px;
    line-height: 22px;
    list-style-type: none !important;

    li {
      list-style-type: none !important;
      margin-bottom: 15px;
      color: $grey-text;
    }

  }
}

.Calibre-Semibold {
  font-family: "Calibre Semibold" !important;
}


.mt-70 {
  margin-top: 70px;

  @media screen and (max-width: 769px) {
    margin-top: 30px;
  }

  &.foot {
    @media screen and (max-width: 1800px) {
      margin-top: 40px;
    }


    @media screen and (max-width: 1270px) {
      margin-top: 120px;
    }

    @media screen and (max-width: 992px) {
      margin-top: 30px;
    }
  }
}

.gradient-text {
  background: linear-gradient(270deg, #556F84 0%, #637D93 50%, #6E7FA4 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: $black-bg;
}

.header-main {
  margin-bottom: 60px;
}

.col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bg-grey {
  background-color: #E1E1E1;
}

.orange-text {
  color: $orange;
}

.cln-text {
  color: $cln-blue
}

.grey-text {
  color: $grey-text;
}

.white-text {
  color: $white-text;
}

.grey-dark {
  color: $primary-btn;
}

.black-bg {
  background-color: $black-bg;
}

.kmb-bg {
  background-color: $ping-light;
}

.no-arr {
  font-family: "Calibre Medium";
  padding: 13px 25px 10px 25px;
  background-color: $black-bg;
  color: $white-text;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  letter-spacing: -0.53px;
  cursor: pointer;
  align-items: center;
  text-decoration: underline;
  z-index: 100;
}

.arrow-button {
  font-family: "Calibre Medium";
  padding: 7px 15px 2px 26px;
  background-color: $black-bg;
  color: $white-text;
  border: none;
  border-radius: 16px;
  font-size: 16px;
  letter-spacing: -0.53px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 17px 10px 0;

 
  &:hover.arrow-button.disabled {
    background-color: $white-text;
    color: rgba(0, 0, 0, 0.5);
    padding: 7px 20.5px 2px 20.5px;

    @media screen and (max-width:1024px) {
      background-color: rgba(0, 0, 0, 0.1) !important;
      color: none !important;
      padding: none !important;
    }
  }

}


.arrow-button {
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 11px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-image: url('../public/Icons/Arrow-Black.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  &:hover {
    &::before {
    }
  }
}

.arrow-button.disabled {
  &::before {
    background-image: none;
  }
}

.arrow-button.disabled {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.5);
  padding: 7px 20.5px 2px 20.5px;
}

.dispaly-flex {
  display: flex;
  flex-wrap: wrap;
}

.slick-carousel {
  margin: 0 auto;
  width: 100%;
}

.slick-slide {
  width: 350px;
}

.slick-next::before,
.slick-prev::before {
  color: blue;
}

.slick-slide.slick-current {
  filter: none;
}



.second-section {
  margin-top: 500px;
  margin-bottom: 500px;
}

.slider-inner-section {
  padding: 32px 16px 16px 16px;

  @media screen and (max-width: 640px) {
    padding: 32px 30px 16px 30px;
  }

  @media screen and (max-width: 400px) {
    padding: 32px 16px 16px 16px;
  }
}

.slick-current {
  background-color: $white-text;
}

.slick-img-div {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  padding-bottom: 88%;
}

.slick-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  object-fit: cover;
}

.kmb-img {
  height: 85%;
}

.slick-top-sec {
  padding-left: 0;

  @media screen and (max-width: 768px) {
    padding-left: 14px;
  }

  @media screen and (max-width: 600px) {
    padding-left: 27px;
  }

  @media screen and (max-width:400px) {
    padding-left: 14px;
  }
}

.show-all {
  position: relative;
}

.show-all {
  &::after {
    content: '';
    position: absolute;
    top: 40%;
    right: -20px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-image: url('../public/Icons/Arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.opt-50 {
  opacity: 0.5;
}

.slick-second-section {
  background-color: $grey-light;
  padding: 35px 35px 35px 30px
}

.kmb-logo {
  height: 80px;
  width: 100%;

  @media screen and (min-width: 400px) {
    height: 80px;
  }

  @media screen and (min-width: 640px) {
    height: 80px;
  }

  @media screen and (min-width: 1200px) {
    height: 100px;
  }

  &.saf {
    height: 30px;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  &.ui {
    height: 50px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  &.kmb {
    height: 70px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

}

.slick-slide {
  position: relative;
}

.slick-slide:not(.slick-current) {
  opacity: 0.4;
  
}

.slick-carousel {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 767px) {
    flex-direction: column !important;
  }
}

.hi-index {
  z-index: 2;
  position: relative;
}

.collbrator-div {
  border-bottom: 4px solid rgba(0, 0, 0, 0.2);
  display: inline-block;
  border-radius: 2px;

  @media screen and (max-width: 1023px) {
    border-bottom: none;
  }
}

.tabs {
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
  color: #A9A7A4;
}

.tabs-active {
  border-bottom: 3px solid rgba(0, 0, 0, 0.3);
  color: $black-bg;
}

.text-t-grey {
  color: $text-t-grey;
}

.collabartor-img {
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: $grey-text;

  &.white {
    background-color: $white-text;
  }

  &.black {
    background-color: $black-bg;
  }

  &.grey {
    background-color: $text-t-grey;
  }

  &.lit-black {
    background-color: #262626;
  }

  &.lit-green {
    background-color: #74D9A9;
  }

  @media screen and (max-width: 1401px) {
    width: 110px;
    height: 110px;
  }

  @media screen and (max-width: 1201px) {
    width: 72px;
    height: 72px;
  }


  @media screen and (max-width: 600px) {
    width: 72px;
    height: 72px;
  }
}

.work-with-us {
  background-color: $grey-sky;
  border-radius: 136px;
  padding: 12px;

  @media screen and (max-width: 540px) {
    border-radius: 0px;
 ;
  }
}

.work-with-us-display {
  display: flex;

  @media screen and (max-width: 540px) {
    display: block;
  }
}

.plus-symbol {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  font-weight: bold;
  color: $grey-text;
}

.footer-box {
  width: 628px;
  background-color: $thik-green;
  margin-left: 60px;
  border-radius: 12px;
  padding: 24px;

  @media screen and (max-width: 1025px) {
    width: 608px;
    margin-left: 40px;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 0px;
  }

  @media screen and (max-width: 769px) {
    width: 100%;
    margin-left: 0px;
  }
}


.custom-input,
.customs-textarea {
  font-family: "Calibre Regular";
  border-radius: 8px;
  width: 100%;
  font-size: 24px;
  padding: 12px 11px 5px 14px;
  line-height: 1;
  text-decoration: none;
  text-decoration-thickness: 1px;

  @media screen and (max-width: 650px) {
    font-size: 16px;
  }

 

  &.half {
    width: 45%;
  }
}

.react-tel-input .form-control {
  height: auto !important;
  width: 100% !important;
  border-radius: 8px !important;
  font-size: 24px !important;
  padding: 12px 11px 8px 48px !important;

  @media screen and (max-width: 650px) {
    font-size: 16px !important;
  }
}

.react-tel-input .flag-dropdown {
  border-radius: 8px 0 0 8px !important;
}

.react-tel-input .selected-flag .flag {
  margin-top: -3px !important;
}

.customs-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;


  /* Custom styles */
  font-family: "Calibre Regular";
  border-radius: 8px;
  width: 100%;
  font-size: 24px;
  padding: 14px 11px 8px 14px;
  line-height: 1;
  text-decoration-thickness: 1px;
  color: #000;
  background-image: url('../public/Icons/DropdownSmBlack.svg');
  background-repeat: no-repeat;
  background-position: right 20px center;

  background-size: 12px;

  @media screen and (max-width: 650px) {
    font-size: 16px;
  }

  &.sin {
    background-color: black;
    color: white;
    padding: 14px 11px 8px 14px;
    text-decoration: none;
    border: 1px solid black;
    background-image: url('../public/Icons/DropdownSmBlack-White.svg');
  }

 


  &.half {
    width: 45%;
  }
}

/* For Internet Explorer */
select::-ms-expand {
  display: none;
}





.custom-input::placeholder,
.customs-select::placeholder,
.customs-textarea::placeholder {
  font-family: "Calibre Regular";
  font-size: 24px;
  color: rgba(0, 0, 0, 0.3);
  opacity: 30;
  text-decoration: none;

  @media screen and (max-width: 650px) {
    font-size: 16px;
  }
}

.width-47 {
  width: 47%;

  @media screen and (max-width: 769px) {
    width: 100%;
  }
}

.drop-area {
  border: 10px solid #33896D;
  border-radius: 8px;
  background-color: #5CA08A;
  text-align: center;
  font-size: 24px;
  padding: 18px;
  text-decoration: none;
  text-decoration-thickness: 1px;
  color: #FFFFFF;
  /* Replace $white-text with actual color code if needed */
  cursor: pointer;
}

.file-input {
  display: none;
}

.submit-arrow {
  width: 48px;
 
}

.mt-neg-390 {
  margin-top: -390px;

  @media screen and (max-width: 992px) {
    margin-top: 0px;
  }

}

.cursor-pointer {
  cursor: pointer;
}

.common-div {
  padding-top: 30px;
  padding-bottom: 90px;

  @media screen and (max-width: 1400px) {
    padding-top: 0px;
    padding-bottom: 50px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 35px;
    padding-bottom: 20px;
  }

}

.inside-div {
  padding-top: 10px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 10px;

  @media screen and (max-width: 1400px) {
    padding-top: 10px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 35px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
  }
}

.inside-img {
  margin-left: -2rem;
}

.inside-logo {
  width: 180px;
  background-color: white;
  height: 80px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 40px 20px rgba(50, 75, 151, 0.9);
  /* Adjust the color and opacity as needed */
}

.inside-logo-wid {
  width: 130px;

}

.inside-logo img {
  display: block;
  border-radius: 10px;
}

.ht-900 {
  height: 900px;
}

.ht-400 {
  height: 500px;
}

.arr-disp {
  display: block;

  @media screen and (max-width: 1400px) {
    display: none;
  }

}

a .sliderss {
  color: $black-bg !important;
}


a {
  color: #A9A7A4 !important;

  .gps {
    color: #000 !important;
  }
}

.inactive-tab a {
  color: #A9A7A4 !important;
}

.active-tab a {
  color: #000 !important;
}

a:hover {
  text-decoration: none !important;
}

.accordion-toggle {
  width: 100%;
  text-align: left;
  border: none;
  background-color: #fff;
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
  transition: border-bottom-color 0.3s;
}

// .accordion-toggle.open .para-head {
//   color: black;
// }

.grey-text {
  color: #A9A7A4;
}

.accordion-toggle.open {
  border-bottom: 3px solid rgba(0, 0, 0, 0.3);
}

.accordion-content {
  display: none;
}

.responsive-newline {
  @media screen and (max-width: 540px) {
    white-space: nowrap;
  }

}

.how-we-work {
  background-image: url('../public/Icons/BackgroundPattern.jpeg');
  background-repeat: repeat;
}

.bor-bottom {
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

select:focus-visible,
textarea:focus-visible,
input:focus-visible {
  outline: none;
}


input {
  border: none;
}

.logos {
  height: 111px;

  @media screen and (max-width: 630px) {
    height: 72px;
  }

}

.col-xl-custom {
  @media screen and (min-width: 1200px) {
    flex: 0 0 38.333333% !important;
    max-width: 38.333333% !important;
  }
}

.pos-relative {
  position: relative;
  display: inline-block;
}

.disp-xxl {
  @media screen and (min-width: 770px) {
    display: none !important;
  }

  @media screen and (min-width:1270px) {
    display: block !important;
  }

  @media screen and (max-width: 992px) {
    display: block !important;
  }
}

.cook-disp {
  display: block;

  @media screen and (max-width: 1439px) {
    display: none;
  }
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.footer-bottom {
  padding-right: 76px;
  padding-left: 76px;
  margin-right: -76px;
  margin-left: -76px;

  @media screen and (max-width: 1200px) {
    padding-right: 42px;
    padding-left: 42px;
    margin-right: -42px;
    margin-left: -42px;
  }

  @media screen and (max-width: 768px) {
    padding-right: 16px;
    padding-left: 16px;
    margin-right: -16px;
    margin-left: -16px;
  }

  @media screen and (max-width: 600px) {
    padding-right: 29px;
    padding-left: 29px;
    margin-right: -29px;
    margin-left: -29px;
  }

  @media screen and (max-width: 400px) {
    padding-right: 16px;
    padding-left: 16px;
    margin-right: -16px;
    margin-left: -16px;
  }
}

.footer-bot-bg {
  background-color: #E1E1E1;

  @media screen and (max-width: 1270px) {
    background-color: #E6E6E6 !important;
  }
}

.container-fl.how-we-work {
  position: relative;
  overflow: hidden;
}

.image-container {
  position: absolute;
  top: 458px;
  width: 265px;
  height: 234px;
  z-index: 6666666;
  left: -95px;

  @media screen and (max-width: 1023px) {
    left: -222px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.image-container-sm {
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    top: 458px;
    width: 265px;
    height: 234px;
    z-index: 6666666;
  }
}

.image-container-sm-rt {
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    width: 265px;
    height: 234px;
    margin-right: -155px;
  }
}

.image-container-right {
  position: absolute;
  top: 1100px;
  width: 265px;
  height: 234px;
  right: -95px;

  @media screen and (max-width: 1023px) {
    top: 1350px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.half-image-1 {
  height: 240px;
  margin-left: -60px;

}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
}


.active-tab {
  border-bottom: 3px solid rgba(0, 0, 0, 0.3);
  color: #000 !important;
}


.inactive-tab {
  color: #A9A7A4 !important;
  border-bottom: 3px solid rgba(0, 0, 0, 0.2);
}

.wid-28 {
  width: 26rem;

  @media screen and (max-width: 1440px) {
    width: 28rem;
  }

}

.top-bot {
  border-bottom: 4px solid $text-t-grey;
}

.opt-10 {
  opacity: 0.3;
}

.org-bot {
  border-bottom: 2px solid $orange;
}

.cln-bot {
  border-bottom: 2px solid $cln-blue;
}

.bck {
  margin-left: -20px;

  @media screen and (max-width: 1023px) {
    margin-left: 0px;
  }
}

.roll-img {
  @media screen and (max-width: 860px) {
    width: 120%
  }

  @media screen and (max-width: 660px) {
    width: 100%
  }

}

.pro-img-div {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  padding-bottom: 56%;

  @media screen and (max-width: 1024px) {
    padding-bottom: 58%;
  }

  &.bots {
    padding-bottom: 64%;
  }
}

.pro-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  object-fit: cover;
}

.label-txt {
  font-size: 16px;
  letter-spacing: -0.3px;

  @media screen and (max-width: 650px) {
    font-size: 12px;
    letter-spacing: -0.23px;
  }

}

.shadow-1 {
  box-shadow: 0 0 20px #ccc;
}

.lh-18 {
  line-height: 18px !important;
}

.error-message {
  font-size: 12px;
  color: red;
  margin-top: 5px;
  
}




// joe code starts
.black_text {
  color: #000;
}

.nav-items {
  font-size: 12px;
  // font-weight: 500;
  font-family: 'Calibre Medium';
  letter-spacing: 1.5px;
  color: #000 !important;
  padding-left: 40px;
}

.primary-btn {
  background-color: none !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 2px 10px;
  border-radius: 16px;
}

.project-page {
  .project-title {
    font-size: 150px;
    font-family: 'Calibre Semibold';
    letter-spacing: -5px;
    color: #000;

    @media screen and (max-width:767px) {
      font-size: 60px !important;
      letter-spacing: -1.33px !important;
    }

    @media screen and (max-width:575px) {
      font-size: 40px !important;
      letter-spacing: -1.33px !important;
    }

    @media screen and (min-width:768px) and (max-width:820px) {
      font-size: 96px !important;
    }
  }

  .project-banner-img {
    width: 100%;
    height: 100%;
  }

  .project-bullet-list {
    @media screen and (max-width:575px) {
      display: block !important;
      text-align: center !important;
    }

    p {
      letter-spacing: 0.33px;
      line-height: 30px;

    }

    p::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 1px solid grey;
      border-radius: 50%;
      margin-right: 10px;

      @media screen and (max-width:575px) {
        display: none !important;
      }
    }
  }

  .project-big-button {
    background-color: #1365AA;
    border-radius: 16px;
    position: relative;
    bottom: 100px;
    border: none;
    padding: 30px 48px;



    @media screen and (max-width:575px) {
      width: 100% !important;
      border-radius: 0px !important;
      position: inherit !important;
    }

    @media screen and (min-width:768px) and (max-width:820px) {
     
      padding: 35px 50px 5px 50px !important;
    }

    @media screen and (min-width:568px) and (max-width:767px) {

    }

   
  }

  .logo-background {
    width: 299px;
    height: 299px;
    border-radius: 19px;
    background-color: rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;

    @media screen and (max-width:575px) {
      background-color: white !important;
      width: 93.23px !important;
      height: auto !important;
      margin-bottom: 0px !important;
    }

    @media screen and (min-width:768px) and (max-width:820px) {
      width: 205px !important;
      height: 205px !important;
      margin-bottom: 190px;
    }
  }
}

.para-sm {
  font-family: "Calibre Regular";
  font-size: 28px;
  // font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 38px;

  @media screen and (max-width: 1025px) {
    font-size: 24px;
    letter-spacing: 0.75px;
    line-height: 34px;
  }

  @media screen and (max-width: 575px) {
    font-size: 16px;
    letter-spacing: 0.33px;
    line-height: 24px;
    text-align: center !important;
  }
}

.mr-15 {
  margin-right: 15%;

  @media screen and (max-width:575px) {
    margin-right: 0px !important;
  }
}

.para-md {
  font-size: 24px;
  letter-spacing: 0.75px;
  font-family: 'Calibre Semibold';
}

.para-xl {
  font-size: 72px;
  letter-spacing: -1px;
  font-family: 'Calibre Semibold';

  @media screen and (max-width:1400px) {
    font-size: 60px !important;
  }

  @media screen and (max-width:767px) {
    font-size: 24px !important;
  }

  @media screen and (min-width:768px) and (max-width:1024px) {
    font-size: 32px !important;
  }
}

.text-decor-unline {
  text-decoration: underline;
}

.dis-none {
  @media screen and (max-width:575px) {
    display: none !important;
  }
}

.mt-nec-25 {
  margin-top: -25px;

  @media screen and (max-width:575px) {
    margin-top: 0px !important;
  }
}

.external-aroww-img {
  width: 32px;
  height: 32px;
  border-radius: 10px;

  @media screen and (max-width:575px) {
    width: 16px !important;
    height: 16px !important;
  }

  &.accor {
    @media screen and (max-width:1024px) {
      width: 24px !important;
      height: 24px !important;
    }

    @media screen and (max-width:768px) {
      width: 16px !important;
      height: 16px !important;
    }
  }
}


#scrollToTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}

.footer-nav {
  font-size: 12px;
  font-family: 'Calibre Regular';
  columns: $grey-light;
}

.cookie-alert {
  position: relative;
  top: 330px;
  right: 0;
  width: 298px;
  height: 40px;
  padding: 8px 0px 10px 15px;
  background-color: black;
  color: white;
  display: none;
  border-radius: 15px;
  font-size: 14px;
  z-index: 999;

  @media screen and (min-width:768px) and (max-width:820px) {
    display: none !important;
  }

  @media screen and (max-width:575px) {
    display: none !important;
  }

  .close-button {
    color: white !important;
    width: 33px !important;
    height: 33px !important;
  }
}

// new silder styles starts
.project-silder .slick-slide {
  padding: 0 20px;

  position: relative;

  @media screen and (min-width:768px) and (max-width:820px) {
    padding: 0 160px;
  }
}



.project-silder .slider {
  text-align: center;
  overflow: visible;
}

.project-silder .slider-img {
  width: 100% !important;
  height: auto;
  margin: 0 0px;
  border-radius: 16px;
  object-fit: cover;

  @media screen and (max-width:820px) {
    width: 600px !important;
    height: auto !important;
    margin: 15px -220px;
  }

  @media screen and (max-width:767px) {
    width: 100% !important;
    height: auto !important;
    margin: 15px -40px;
    height: 400px !important;
  }

  @media screen and (max-width:540px) {
    width: 100% !important;
    height: auto !important;
    margin: 15px -40px;
    height: 300px !important;
  }

  

}

.project-silder {

  .slick-prev,
  .slick-next {
    background-size: contain;
    background-repeat: no-repeat;
    width: 40px;
    /* Set the width of your arrow */
    height: 40px;
    /* Set the height of your arrow */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .slick-prev {
    left: 15rem;
    background-image: url('../public/Icons/SlideShowLeft.svg') !important;

    @media screen and (max-width:1025px) {
      left: 3.5rem !important;
    }


    @media screen and (max-width:575px) {
      left: 15rem !important;
      display: none !important;
    }

    @media screen and (max-width:820px) {
      display: none !important;
    }
  }

  .slick-next {
    right: 15rem;
    background-image: url('../public/Icons/SlideShowRight.svg') !important;

    @media screen and (max-width:1200px) {
      right: 15rem !important;
    }

    @media screen and (max-width:1025px) {
      right: 3.5rem !important;
    }

    @media screen and (max-width:821px) {
      right: 7.8rem !important;
    }

    @media screen and (max-width:800px) {
      right: 6.8rem !important;
    }

    @media screen and (max-width:767px) {
      right: 1.5rem !important;
    }
  }

 

  .slick-next::before {
    content: none !important;
  }

  .slick-prev::before {
    content: none !important;
  }

  .slick-next:focus,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-prev:hover {
    width: 40px !important;
    height: 40px !important;
  }

  .silder-content {
    margin-top: 20px;
    font-family: 'Calibre Medium';
    font-size: 16px;
    letter-spacing: -0.22px;

    @media screen and (min-width:768px) and (max-width:820px) {
      margin: 20px 30% 0px 0px !important;
    }

    @media screen and (max-width:575px) {
      margin: 5px 20% 0px 0px !important;
    }
  }
}

.margin-top-neg {
  margin-top: -100px;
}

.footer-logo-background {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.mr-10 {
  @media screen and (max-width:575px) {
    margin-right: 17rem;
  }
}

// new silder styles ends
.printing-page {
  .printing-para {
    font-size: 58px;
    // font-weight: 500;
    font-family: 'Calibre Medium';
    letter-spacing: 0.56px;
    line-height: 1;
    color: #000;

    @media screen and (max-width:575px) {
      font-size: 40px !important;
    }

    @media screen and (min-width:768px) and (max-width:820px) {
      font-size: 64px !important;
    }
  }

  .explaination-box {
    max-width: 600px;
    max-height: 115px;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 27px;
    position: relative;
    bottom: 35%;

    @media screen and (max-width: 575px) {
      bottom: 0px !important;
      padding: 20px 0px !important;
      margin: 0px 25% 0px 25%;
    }

    @media screen and (min-width:768px) and (max-width:1180px) {
      width: 200px !important;
    }
  }

  .para-smller {
    font-family: "Calibre Regular";
    font-size: 28px;
    letter-spacing: 0.5px;
    line-height: 38px;

    @media screen and (max-width: 1025px) {
      font-size: 24px;
      letter-spacing: 0.75px;
      line-height: 34px;
      padding-right: 0px !important;

    }

    @media screen and (max-width: 575px) {
      font-size: 18px;
      letter-spacing: 0.33px;
      line-height: 24px;
      padding-right: 0px !important;
    }

  }

  .object-fit-cover {
    object-fit: cover !important;
  }

  .dis-none-md {
    @media screen and (min-width:768px) and (max-width:820px) {
      display: none !important;
    }
  }

  .dis-none-xl {
    @media screen and (min-width:768px) and (max-width:1180px) {
      display: none !important;
    }
  }


  .pdc-white-logo {
    background-color: black;
    margin: 0% 39%;
    padding: 40px 0px;
    border-radius: 10px;

    @media screen and (max-width: 575px) {
      margin: 0% 25% !important;
    }

    @media screen and (min-width:768px) and (max-width:820px) {
      margin: 0% 30% !important;
    }
  }

  .illustrative-img-res {
    position: absolute;
    right: -76px;
    bottom: -223px;
    z-index: 0;
    opacity: 0.2;
  }

  .illustrative-img {
    position: absolute;
    right: -207%;
    bottom: -267px;
    width: 80%;

    @media screen and (max-width:1400px) {
      width: 100%;
    }

    @media screen and (min-width:768px) and (max-width:1023px) {
      position: absolute !important;
      bottom: 27% !important;
      width: 248px !important;
    }

    
  }

  .dis-flex-sm {
    @media screen and (min-width: 768px) and (max-width:820px) {
      display: flex !important;
    }
  }

  .learn-more-para {
    font-size: 14px;

    @media screen and (max-width:575px) {
      font-size: 11px !important;
    }
  }
}

.projects_logo_img {
  height: auto;
  width: 200px;

  @media screen and (min-width: 768px) and (max-width:820px) {
    width: 150px !important;
    margin-top: 30px !important;
  }
}

.visit_website_font {
  @media screen and (min-width: 768px) and (max-width:820px) {
    font-size: 16px !important;
  }
}

// joe code ends
.container-full {
  margin-right: -76px;
  margin-left: -76px;
  margin-top: -32px;

  @media screen and (max-width: 1200px) {
    margin-right: -42px;
    margin-left: -42px;
    margin-top: -42px;
  }

  @media screen and (max-width: 768px) {
    margin-right: -16px;
    margin-left: -16px;
    margin-top: -42px;
  }

  @media screen and (max-width: 600px) {
    margin-right: -29px;
    margin-left: -29px;
    margin-top: -42px;
  }

  @media screen and (max-width: 400px) {
    margin-right: -16px;
    margin-left: -16px;
    margin-top: -32px;
  }
}

section {
  display: none;
}

section#homes {
  display: block;
}

.Silder_text {
  a {
    .silder-title-text {
      color: #000 !important;
    }
  }

  Link {
    .silder-title-text {
      color: #000 !important;
    }
  }
}

.liftTop {
  margin-top: -25px;
}

.bg-red {
  background-color: red;
}

.bg-green {
  background-color: green;
}

.bg-yellow {
  background-color: yellow;
}

////////////////////////////////////////////////////////
/// 
.container-ani {
  position: relative;
  width: 100%;
  // height:  7920px;
  overflow: hidden;
  padding-bottom: 453%;

  @media (max-width: 768px) {
    padding-bottom: 725%;
  }

  @media (max-width: 576px) {
    padding-bottom: 1035%;
  }

  @media (max-width: 430px) {
    padding-bottom: 2350%; // Adjust the value for screens <= 375px
  }
}

.container-ani-pro {
  position: relative;
  width: 100%;
  // height:  7920px;
  overflow: hidden;
  padding-bottom: 315%;

  @media (max-width: 768px) {
    padding-bottom: 525%;
  }

  @media (max-width: 576px) {
    padding-bottom: 735%;
  }

  @media (max-width: 375px) {
    padding-bottom: 1050%; // Adjust the value for screens <= 375px
  }
}

.buttons {
  margin-bottom: 20px;
}



.sticky-part {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  // background: white;
  padding: 10px;
}



.sticky-part.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: white;
}

.content {
  display: flex;
  flex-direction: column;
}

.sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  background-color: yellow;
  padding: 50px;
  font-size: 20px;
}

.spacer {
  background-color: #ccc;
  color: #000;
  margin: 15px 0;
  padding: 15px;
  border: 1px solid #000;
}

.linked {
  margin-top: -10px;

  @media (max-width: 1270px) {
    margin-top: 20px;
  }
}

.print-img-div {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  padding-bottom: 70%;

  &.full {
    padding-bottom: 100%;
  }
}

.print-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  object-fit: cover;
}

.pdc-top {
  margin-top: -580px;

  @media (max-width: 1410px) {
    margin-top: -500px;
  }

  @media (max-width: 1210px) {
    margin-top: -460px;
  }

  @media (max-width: 1050px) {
    margin-top: -400px;
  }

  @media (max-width: 1000px) {
    margin-top: 0px;
  }
}

.service-pad {
  padding-left: 0.9rem;
}

.react-tel-input .country-list {
  margin: 2px 0 10px -1px !important;
  border-radius: 8px !important;
}



.ac-black-text{

  color:#000;
  font-family: "Calibre Semibold";
  font-size: 24px;
  letter-spacing: 0.19px;

  @media screen and (max-width: 1025px) {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 22px;
  }
}

.ac-grey-text{
color: #A9A7A4;
font-family: "Calibre Semibold";
font-size: 24px;
letter-spacing: 0.19px;

@media screen and (max-width: 1025px) {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 22px;
}

}

.grecaptcha-badge{

right: 100px !important;
width: 70px !important;

}


.alert-box{


  margin: 10px;
}
